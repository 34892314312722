<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-home"></i><strong> Entrepôt: {{warehouseName}}</strong>
              <div class="card-header-actions">
                
                <b-modal :title="newModalTitle" class="modal-primary" v-model="newModal"
                         @hide="onNewModalClose">
                  <b-form>
                    <div class="text-center">
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                    </div>
                    
                    <c-input container-class="mb-3 mt-3" type="select" :label="newItemInputLabel"
                             v-model="newSelectedItem" :options="newItemOptions" :state="newItemState">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez {{newItemLabel}} --</option>
                      </template>
                      Veuillez sélectionner {{newItemLabel}}
                    </c-input>
                    
                    <c-input container-class="mb-3" type="quantity" label="Quantité" placeholder="Ex: 12"
                             v-model="quantity" :unit="newItemUnit" :state="quantityState">
                      Veuillez entrer une quantité
                    </c-input>
                  </b-form>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitNew"
                                    :fetching="fetchingNew">
                      Ajouter
                    </button-spinner>
                    <b-button variant="secondary" @click="newModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                
                <b-modal :title="manageModalTitle" class="modal-primary" v-model="manageModal"
                         @hide="onManageModalClose">
                  <b-form>
                    <div class="text-center">
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                      
                      <b-image thumbnail :src="_picture" alt="Image" class="mb-2 manage-modal-input-img"></b-image>
                      <p class="text-muted">{{selectedName}}</p>
                    </div>
                    
                    <c-input container-class="mb-3 mt-3" type="select" label="Opération"
                             v-model="operation" :options="operations" :state="operationState">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez une opération --</option>
                      </template>
                      Veuillez sélectionner une opération
                    </c-input>
                    
                    <c-input container-class="mb-3" type="quantity" label="Quantité" placeholder="Ex: 12"
                             v-model="quantity" :unit="selectedUnit" :state="quantityState">
                      Veuillez entrer une quantité
                    </c-input>
                    
                    <b-input-group class="mb-3">
                      <b-form-textarea class="form-control" placeholder="Commentaire"
                                       v-model="comment"></b-form-textarea>
                    </b-input-group>
                  </b-form>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitManage"
                                    :fetching="fetchingManage">
                      Valider
                    </button-spinner>
                    <b-button variant="secondary" @click="manageModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                
                <b-modal title="Transfert du matériel avant suppression" class="modal-primary" v-model="transfertModal"
                         @hide="onTransfertWarehouseModalModalClose">
                  <b-form>
                    <description-message>
                      Les equipements, intrants, etc. de cet entrepôt seront integralement
                      transférés dans l'entrepôt que vous allez selectionner
                    </description-message>
                    <c-input container-class="mb-3 mt-3" type="select" label="Entrepôt" v-model="warehouseTransfert"
                             :options="warehousesTransfert" :state="warehouseStateTransfert">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez un entrepôt --</option>
                      </template>
                      Veuillez sélectionner un entrepôt
                    </c-input>
                  </b-form>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="warning" type="submit" @click="onTransfertWareHouse"
                                    :fetching="fetchingTransfertWarehouse">
                      Transférer
                    </button-spinner>
                    <b-button variant="secondary" class="ml-2" @click="transfertModal = false">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              
              </div>
            </div>
            <div class="text-center" v-if="fetchingWarehouseData">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <b-tabs v-else-if="warehouseData" content-class="mt-3 border-0" v-model="tabIndex">
              <b-tab>
                <template slot="title">
                  <i class="fa fa-envira"></i> Intrants
                </template>
                <b-button variant="primary" @click="newModal = true" class="mb-2">
                  <i class="fa fa-plus"></i>
                  Ajouter un intrant
                </b-button>
                <c-input v-model="selectedInput" container-class="w-25" placeholder="Saisissez pour filtrer"></c-input>
                <b-row>
                  <c-table :table-data="inputsListAfterFiltered" :fields="fields" :actions="actions" :per-page=8 striped
                           outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucun intrant dans cet entrepôt.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-wrench"></i> Matériel
                </template>
                <b-button variant="primary" @click="newModal = true" class="mb-2">
                  <i class="fa fa-plus"></i>
                  Ajouter un matériel
                </b-button>
                <c-input v-model="selectedEquipment" container-class="w-25"
                         placeholder="Saisissez pour filtrer"></c-input>
                <b-row>
                  <c-table :table-data="equipmentsListAfterFiltered" :fields="fields" :actions="actions" :per-page=8
                           striped outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucun matériel dans cet entrepôt.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-inbox"></i> Produits
                </template>
                <b-button variant="primary" @click="newModal = true" class="mb-2">
                  <i class="fa fa-plus"></i>
                  Ajouter un produit
                </b-button>
                <c-input v-model="selectedProduct" container-class="w-25"
                         placeholder="Saisissez pour filtrer"></c-input>
                <b-row>
                  <c-table :table-data="productsListAfterFiltered" :fields="productsFields" :actions="actionsProducts"
                           :per-page=8
                           striped outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucun produit dans cet entrepôt.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-inbox"></i> Opérations sur les intrants
                </template>
                <b-row>
                  <b-col v-if="fetchingInputsOpsList">
                    <div class="text-center">
                      <b-spinner></b-spinner>
                      <br>
                      Chargement...
                    </div>
                  </b-col>
                  <c-table :table-data="inputsOps" :fields="fieldsOpInputs"
                           :per-page=8
                           striped outlined v-else>
                    <template slot="empty">
                      <p class="text-center">Aucune opération sur les intrants.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
              <b-tab>
                <template slot="title">
                  <i class="fa fa-inbox"></i> Opérations sur les équipements
                </template>
                <b-row>
                  <b-col v-if="fetchingEquipmentsOpsList">
                    <div class="text-center">
                      <b-spinner></b-spinner>
                      <br>
                      Chargement...
                    </div>
                  </b-col>
                  <c-table :table-data="equipmentsOps" :fields="fieldsOpEquipments"
                           :per-page=8
                           striped outlined v-else>
                    <template slot="empty">
                      <p class="text-center">Aucune opération sur les équipements.</p>
                    </template>
                  </c-table>
                </b-row>
              </b-tab>
            </b-tabs>
            <p class="text-center text-danger" v-else>{{errorMessage}}</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, asset, env} from "../../../helpers/index";
  import {endGuide, functionGeneratorJSON} from "../../../helpers";
  
  export default {
    name: "ExploitationInputs",
    title: "PIA - Intrants agricoles",
    data() {
      return {
        fetchingWarehouseData: true,
        warehouseId: this.$route.params.wid,
        warehouseData: null,
        tabIndex: 0,
        fetchingInputList: false,
        fetchingInputsOpsList: false,
        fetchingEquipmentsOpsList: false,
        inputs: [],
        fetchingEquipmentList: false,
        equipments: [],
        fetchingProductList: false,
        products: [],
        inputsOps: [],
        equipmentsOps: [],
        newSelectedItem: null,
        fields: [
          {key: 'picture', label: 'Aperçu', asset: this.asset},
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'qty', label: 'Quantité'},
          {key: 'actions', label: 'Actions'},
        ],
        productsFields: [
          {key: 'picture', label: 'Aperçu', asset: this.asset},
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'qty', label: 'Quantité'},
          {key: 'actions', label: 'Actions'},
        ],
        actions: [
          {type: 'success', icon: 'icon-refresh', title: 'Gérer', handler: this.manage},
          {type: 'warning', icon: 'fa fa-exchange', title: 'Transférer', handler: this.transfert},
        ],
        actionsProducts: [
          {type: 'warning', icon: 'fa fa-exchange', title: 'Transférer', handler: this.transfert},
        ],
        fieldsOpInputs: [
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'status', label: 'Operation'},
          {key: 'qty', label: 'Quantité'},
          {key: 'state', label: 'Automatique/Manuel'},
          {key: 'personName', label: 'Initiée par'},
          {key: 'productionOrHarvestName', label: 'Production/Récolte'},
          {key: 'culture', label: 'Culture'},
          {key: 'cultureStep', label: 'Etape de Cutlure'},
          {key: 'activity', label: 'Activité'},
          {key: 'date', label: 'Date'},
        ],
        fieldsOpEquipments: [
          {key: 'name', label: 'Nom', sortable: true},
          {key: 'status', label: 'Operation'},
          {key: 'qty', label: 'Quantité'},
          {key: 'state', label: 'Automatique/Manuel'},
          {key: 'personName', label: 'Initiée par'},
          {key: 'productionOrHarvestName', label: 'Production/Récolte'},
          {key: 'culture', label: 'Culture'},
          {key: 'cultureStep', label: 'Etape de Cutlure'},
          {key: 'activity', label: 'Activité'},
          {key: 'date', label: 'Date'},
        ],
        newModal: false,
        fetchingNew: false,
        submitted: false,
        error: null,
        selected: null,
        selectedTransfert: null,
        picture: null,
        quantity: '',
        comment: '',
        manageModal: false,
        transfertModal: false,
        fetchingManage: false,
        operations: [],
        operation: null,
        fetchingOperationList: false,
        parcel: null,
        cultureStep: null,
        fetchingParcelList: false,
        
        selectedInput: '',
        selectedEquipment: '',
        selectedProduct: '',
        
        warehouseTransfert: null,
        warehousesTransfert: [],
        fetchingTransfertWarehouse: false,
        submittedTransfert: false,
      }
    },
    created() {
      this.fetchingWarehouseData = true
      Api.get('/exploitation/warehouse', {
        exploitationId: this.exploitationId,
        warehouseId: this.warehouseId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            const {data} = res.data
            const mapper = item => ({
              ...item,
              qty: item.quantity + (item.unit ? ' ' + item.unit : (item.product || item.product_id ? ' Kg' : ''))
            })
            this.warehouseData = {
              ...data,
              inputs: data.inputs.map(mapper).map(item => ({...item, isInput: true})),
              equipments: data.equipments.map(mapper).map(item => ({...item, isEquipment: true})),
              products: data.products.map(mapper).map(item => ({...item, isProduct: true})),
            }
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingWarehouseData = false
        })
      
      this.fetchingOperationList = true
      Api.get('/input/operation/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.operations = res.data.data.map(item => ({
              value: item.id,
              text: item.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingOperationList = false
        })
      
      this.fetchingParcelList = true
      this.fetchingInputList = true
      Api.get('/exploitation/input/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.inputs = res.data.data.map(item => ({
              text: item.name,
              value: {
                id: item.id,
                unit: item.unit
              }
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingInputList = false
        })
      
      this.fetchingEquipmentList = true
      Api.get('/exploitation/equipment/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.equipments = res.data.data.map(item => ({
              text: item.name,
              value: {
                id: item.id,
                unit: item.unit
              }
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingEquipmentList = false
        })
  
      Toast.guide_msg(
        "Vous pouvez ajouter des intrants et des equipements dans votre entrepôt. \n" +
        "Cliquez sur les menus et les boutons ci-dessous pour creer des intrants et equipements " +
        "à mettre dans votre entrepôt",
        this.warehouseListRedirect,
        this.openEmployeesList,
        functionGeneratorJSON("Etape précédente"),
        functionGeneratorJSON("Etape suivante"),
        3,
        [this.exploitationId, this.warehouseId],
        null,
        null,
        null,
        null,
        endGuide
      )
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
      tabIndex() {
        if (this.tabIndex == 3) {
          this.fetchInputsOps()
        }
        else if (this.tabIndex == 4) {
          this.fetchEquipmentsOps()
        }
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      warehouseName() {
        return this.warehouseData ? this.warehouseData.name : '---'
      },
      selectedName() {
        return this.selected ? this.selected.name : '---'
      },
      selectedUnit() {
        return this.selected ? this.selected.unit : '--'
      },
      _picture() {
        return this.picture
          ? this.picture
          : this.tabIndex === 0 ? env('DEFAULT_INPUT_PICTURE') : env('DEFAULT_EQUIPMENT_PICTURE')
      },
      newItemState() {
        return !this.submitted || this.newSelectedItem != null ? null : false
      },
      quantityState() {
        return !this.submitted || Regex.isPositiveNumber(this.quantity) ? null : false
      },
      operationState() {
        return !this.submitted || this.operation != null ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      newModalTitle() {
        return ['Ajouter un intrant', 'Ajouter un matériel', 'Ajouter un produit'][this.tabIndex]
      },
      manageModalTitle() {
        return ['Gérer l\'intrant', 'Gérer le matériel', 'Gérer le produit'][this.tabIndex]
      },
      newItemOptions() {
        if (!this.warehouseData)
          return []
        
        return [
          this.inputs.filter(item => !this.warehouseData.inputs.find(el => el.id === item.value.id)),
          this.equipments.filter(item => !this.warehouseData.equipments.find(el => el.id === item.value.id)),
          this.products.filter(item => !this.warehouseData.products.find(el => el.id === item.value.id)),
        ][this.tabIndex]
      },
      newItemLabel() {
        return ['un intrant', 'un matériel', 'un produit'][this.tabIndex]
      },
      newItemIcon() {
        return ['fa fa-envira', 'fa fa-wrench', 'fa fa-inbox'][this.tabIndex]
      },
      newItemInputLabel() {
        return ['Intrant', 'Matériel', 'Produit'][this.tabIndex]
      },
      newItemUnit() {
        return this.newSelectedItem ? this.newSelectedItem.unit : '--'
      },
      inputsListAfterFiltered() {
        const newValue = this.selectedInput.replace(/(^\s+|\s+$)/g, "").toLowerCase()
        return this.warehouseData.inputs.filter(item => {
          return item.name.toLowerCase().indexOf(newValue) != -1
        })
      },
      equipmentsListAfterFiltered() {
        const newValue = this.selectedEquipment.replace(/(^\s+|\s+$)/g, "").toLowerCase()
        return this.warehouseData.equipments.filter(item => {
          return item.name.toLowerCase().indexOf(newValue) != -1
        })
      },
      productsListAfterFiltered() {
        const newValue = this.selectedProduct.replace(/(^\s+|\s+$)/g, "").toLowerCase()
        return this.warehouseData.products.filter(item => {
          return item.name.toLowerCase().indexOf(newValue) != -1
        })
      },
      warehouseStateTransfert() {
        return !this.submittedTransfert || this.warehouseTransfert != null ? null : false
      }
    },
    methods: {
      asset(path) {
        return asset(
          path,
          env(['DEFAULT_INPUT_PICTURE', 'DEFAULT_EQUIPMENT_PICTURE', 'DEFAULT_CULTURE_PICTURE'][this.tabIndex])
        )
      },
      reset() {
        this.submitted = false
        this.error = null
        this.picture = null
        this.quantity = ''
      },
      onNewModalClose() {
        this.reset()
        this.fetchingNew = false
        this.newSelectedItem = null
      },
      onManageModalClose() {
        this.reset()
        this.comment = ''
        this.fetchingManage = false
        this.operation = null
        this.parcel = null
        this.cultureStep = null
      },
      valid() {
        return this.newSelectedItem && Regex.isPositiveNumber(this.quantity)
      },
      onSubmitNew() {
        this.submitted = true
        if (!this.valid()) return
        
        const urlParam = ['input', 'equipment', 'product'][this.tabIndex]
        
        this.fetchingNew = true
        Api.post(`/exploitation/warehouse/${urlParam}/add`, {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouseId,
          inputId: this.newSelectedItem.id,
          equipmentId: this.newSelectedItem.id,
          productId: this.newSelectedItem.id,
          quantity: this.quantity
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.newModal = false
              const item = res.data.data
              let list = [this.warehouseData.inputs, this.warehouseData.equipments, this.warehouseData.products][this.tabIndex]
              list.push({
                ...item,
                qty: item.quantity + (item.unit ? ' ' + item.unit : '')
              })
              this.warehouseData[urlParam + 's'] = list
              
              const el = ['Intrant', 'Matériel', 'Produit'][this.tabIndex]
              Toast.success(`${el} ajouté avec succès !`)
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingNew = false
          })
      },
      selectItem(item) {
        this.selected = item
        this.picture = asset(item.picture)
      },
      manage(item) {
        this.selectItem(item)
        this.quantity = ''
        this.comment = ''
        this.manageModal = true
      },
      async transfert(item) {
        console.log(item)
        this.selectedTransfert = item
        this.quantity = ''
        this.comment = ''
        
        const rest_list = await Api.get('/exploitation/warehouse/list', {
          exploitationId: this.exploitationId
        });
        if (rest_list.data.status === 'success' && rest_list.data.data) {
          const list_warehouses = rest_list.data.data
          if (list_warehouses.length <= 1)
            Toast.error("Aucun entrepot disponible pour effectuer le transfert")
          else {
            this.warehousesTransfert = list_warehouses.filter(item => item.id != this.warehouseData.id).map(item => ({
              value: item.id,
              text: item.name
            }))
            this.transfertModal = true
          }
        } else {
        }
      },
      validManage() {
        return Regex.isPositiveNumber(this.quantity) && this.operation != null
      },
      onSubmitManage() {
        this.submitted = true
        if (!this.validManage()) return
        
        const urlParam = ['input', 'equipment', 'product'][this.tabIndex]
        
        this.fetchingManage = true
        Api.post(`/exploitation/${urlParam}/operation`, {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouseId,
          inputId: this.selected.id,
          equipmentId: this.selected.id,
          inputOperationId: this.operation,
          equipmentOperationId: this.operation,
          quantity: this.quantity,
          parcelId: this.parcel,
          cultureStepId: this.cultureStep,
          comment: this.comment
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              this.manageModal = false
              const item = res.data.data
              let list = [this.warehouseData.inputs, this.warehouseData.equipments, this.warehouseData.products][this.tabIndex]
              list = list.filter(item => item.id !== this.selected.id)
              list.push({
                ...item,
                qty: item.quantity + (item.unit ? ' ' + item.unit : '')
              })
              this.warehouseData[urlParam + 's'] = list
              
              Toast.success('Opération effectuée avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingManage = false
          })
      },
      fetchInputsOps() {
        this.fetchingInputsOpsList = true
        Api.get('/exploitation/warehouse/operation-input/list', {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouseId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.inputsOps = res.data.data.map(item => {
                const operation = item.inputOperation
                const message = operation.id == 1 ? "Ajouté(e) le " : "Rétiré(e) le "
                const statusVariant = operation.id == 1 ? 'success' : 'warning'
                if (item.production) {
                  return {
                    name: item.input.name,
                    status: operation.name,
                    statusVariant: statusVariant,
                    qty: item.quantity + " " + item.input.unit,
                    state: "Automatique",
                    personName: item.user.firstName + " " + item.user.lastName,
                    productionOrHarvestName: item.production.name,
                    culture: item.culture.name,
                    cultureStep: item.cultureStep.name,
                    activity: item.activity.name,
                    date: message + item.operationDate.split(' ').join(' à ')
                  }
                }
                else if (item.harvest) {
                  return {
                    name: item.input.name,
                    status: operation.name,
                    statusVariant: statusVariant,
                    qty: item.quantity + " " + item.input.unit,
                    state: "Automatique",
                    personName: item.user.firstName + " " + item.user.lastName,
                    productionOrHarvestName: item.harvest.name,
                    culture: item.culture.name,
                    cultureStep: "-",
                    activity: item.activity.name,
                    date: message + item.operationDate.split(' ').join(' à ')
                  }
                }
                else {
                  return {
                    name: item.input.name,
                    status: operation.name,
                    statusVariant: statusVariant,
                    qty: item.quantity + " " + item.input.unit,
                    state: "Manuel",
                    personName: item.user.firstName + " " + item.user.lastName,
                    productionOrHarvestName: "-",
                    culture: "-",
                    cultureStep: "-",
                    activity: "-",
                    date: message + item.operationDate.split(' ').join(' à ')
                  }
                }
              })
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingInputsOpsList = false
          })
      },
      fetchEquipmentsOps() {
        this.fetchingEquipmentsOpsList = true
        Api.get('/exploitation/warehouse/operation-equipment/list', {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouseId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.equipmentsOps = res.data.data.map(item => {
                const operation = item.equipmentOperation
                const message = operation.id == 1 ? "Ajouté(e) le " : "Rétiré(e) le "
                const statusVariant = operation.id == 1 ? 'success' : 'warning'
                if (item.production) {
                  return {
                    name: item.equipment.name,
                    status: operation.name,
                    statusVariant: statusVariant,
                    qty: item.quantity,
                    state: "Automatique",
                    personName: item.user.firstName + " " + item.user.lastName,
                    productionOrHarvestName: item.production.name,
                    culture: item.culture.name,
                    cultureStep: item.cultureStep.name,
                    activity: item.activity.name,
                    date: message + item.operationDate.split(' ').join(' à ')
                  }
                }
                else if (item.harvest) {
                  return {
                    name: item.equipment.name,
                    status: operation.name,
                    statusVariant: statusVariant,
                    qty: item.quantity,
                    state: "Automatique",
                    personName: item.user.firstName + " " + item.user.lastName,
                    productionOrHarvestName: item.harvest.name,
                    culture: item.culture.name,
                    cultureStep: "-",
                    activity: item.activity.name,
                    date: message + item.operationDate.split(' ').join(' à ')
                  }
                }
                else {
                  return {
                    name: item.equipment.name,
                    status: operation.name,
                    statusVariant: statusVariant,
                    qty: item.quantity,
                    state: "Manuel",
                    personName: item.user.firstName + " " + item.user.lastName,
                    productionOrHarvestName: "-",
                    culture: "-",
                    cultureStep: "-",
                    activity: "-",
                    date: message + item.operationDate.split(' ').join(' à ')
                  }
                }
              })
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingEquipmentsOpsList = false
          })
      },
      onTransfertWareHouse() {
        
        this.submittedTransfert = true
        if (!this.validTransfert())
          return
        
        this.fetchingTransfertWarehouse = true
        const element = this.selectedTransfert
        Api.post('/exploitation/warehouse/transfert/one', {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouseData.id,
          newWarehouseId: this.warehouseTransfert,
          inputId: element.isInput ? element.id : null,
          equipmentId: element.isEquipment ? element.id : null,
          productId: element.isProduct ? element.id : null,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              if (element.isInput) {
                this.warehouseData.inputs = this.warehouseData.inputs.filter(item => item.id != element.id)
                Toast.success('Intrant transféré avec succès !')
                this.transfertModal = false
              }
              else if (element.isEquipment) {
                this.warehouseData.equipments = this.warehouseData.equipments.filter(item => item.id != element.id)
                Toast.success('Equipement transféré avec succès !')
                this.transfertModal = false
              }
              else {
                this.warehouseData.products = this.warehouseData.products.filter(item => item.id != element.id)
                Toast.success('Produit transféré avec succès !')
                this.transfertModal = false
              }
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingTransfertWarehouse = false
          })
        
      },
      onTransfertWarehouseModalModalClose() {
        this.warehouseTransfert = null
      },
      validTransfert() {
        return this.warehouseTransfert != null
      },
      warehouseListRedirect() {
        this.$router.push('/exploitation/'+this.exploitationId+'/management/warehouses/list')
      },
      openEmployeesList() {
        this.$router.push('/exploitation/'+this.exploitationId+'/staff/employees')
      },
    }
  }
</script>

<style scoped>
  #input-img{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  
  .manage-modal-input-img{
    max-height: 100px;
  }
</style>
